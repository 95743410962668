$primary-color: black;
$numVals: 12;

@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

body {
    @include flex-center;
    background-color: $primary-color;
    color: white;
    min-height: 100vh;
    font-family: 'Courier New', Courier, monospace;
    text-align: center;
}

@mixin array-margins {
    @for $i from 1 through $numVals {
      &:nth-child(#{$i}) {
        width: calc(100% / $numVals);
        left: calc((100% / $numVals) * ($i - 1) - 2px);
        transition: left 0.2s linear;
      }
    }
}

.array-container {
    .array {
        position: absolute;
        top: calc(50vh - 50px);
        left: 10%;
        width: 80%;
        .cell-container {
            position: absolute;
            @include array-margins;
            @include flex-center;
            .cell {
                width: 25px;
                height: 60px;
                padding: 10px;
                .val {
                    border: 1px solid white;
                    height: 25px;
                    line-height: 25px;
                }
                .idx {
                    margin-top: 10px;
                    height: 25px;
                }
            }
        }
    }
}